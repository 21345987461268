window.jQuery = window.$ = require('jquery');
require('bootstrap');
window.Popper = require('popper.js').default;
window.wNumb = require('wnumb');
window.moment = require('moment');
require("es6-shim/es6-shim.min.js");
window.PerfectScrollbar = require('perfect-scrollbar/dist/perfect-scrollbar');
window.Sticky = require('sticky-js');
require('tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4');
require('../keen/src/js/vendors/plugins/tempusdominus-bootstrap-4.init.js');
require('bootstrap-daterangepicker/daterangepicker.js');
require('select2/dist/js/select2.full.js');


require('bootstrap-timepicker/js/bootstrap-timepicker.js');
require('../keen/src/js/vendors/plugins/bootstrap-timepicker.init.js');

require('es6-promise-polyfill/promise.min.js');
window.Swal = window.swal = require('sweetalert2/dist/sweetalert2.min.js');
require('../keen/src/js/vendors/plugins/sweetalert2.init.js');

const { Dropzone } = require("dropzone");
require('../keen/src/js/vendors/plugins/dropzone.init.js');


window.JSZip = require( 'jszip' );
require( 'datatables.net-bs4' );
require( 'datatables.net-buttons-bs4' );       
require( 'datatables.net-buttons/js/buttons.html5.js' );
require('./dataTables.keepConditions.min.js');
require('./dataTables.checkboxes.js');
// require( 'datatables.net-buttons/js/buttons.print.js' );
require( 'datatables.net-responsive-bs4' );

require("bootstrap-select/dist/js/bootstrap-select.min.js");

require('jstree');
require('./jQuery.print.min.js');


// $(document).ready(function () {
	$.fn.selectpicker.Constructor.BootstrapVersion = '4';
	$.fn.select2.defaults.set("theme", "bootstrap4");
	$(document).on('select2:open', () => {
		document.querySelector('.select2-container--open .select2-search__field').focus();
	});
// });